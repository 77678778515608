// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body {\n  margin: 0;\n  padding: 0;\n  font-family: \"Share Tech Mono\", monospace;\n  background-color: #131418;\n  color: #959da5;\n  font-size: 14px;\n}\n\nbutton,\ninput,\ntextarea,\nselect {\n  font-family: \"Share Tech Mono\", monospace;\n}\n\na {\n  color: #ffffff;\n  text-decoration: none;\n}\n\ntable {\n  border-collapse: collapse;\n}\n\ntable td,\nth {\n  border: 2px solid black;\n}\n\ntable tr:first-child td,\nth {\n  border-top: 0;\n}\n\ntable tr td:first-child,\nth:first-child {\n  border-left: 0;\n}\n\ntable tr:last-child td {\n  border-bottom: 0;\n}\n\ntable tr td:last-child,\nth:last-child {\n  border-right: 0;\n}", "",{"version":3,"sources":["webpack://./src/app.scss"],"names":[],"mappings":"AAGA;EACE,SAAA;EACA,UAAA;EACA,yCAAA;EACA,yBAAA;EACA,cAAA;EACA,eAAA;AAAF;;AAGA;;;;EAIE,yCAAA;AAAF;;AAGA;EACE,cAAA;EACA,qBAAA;AAAF;;AAGA;EACE,yBAAA;AAAF;;AAGA;;EAEE,uBAAA;AAAF;;AAGA;;EAEE,aAAA;AAAF;;AAEA;;EAEE,cAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;;EAEE,eAAA;AACF","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');\n\nbody {\n  margin: 0;\n  padding: 0;\n  font-family: 'Share Tech Mono', monospace;\n  background-color: #131418;\n  color: #959da5;\n  font-size: 14px;\n}\n\nbutton,\ninput,\ntextarea,\nselect {\n  font-family: 'Share Tech Mono', monospace;\n}\n\na {\n  color: #ffffff;\n  text-decoration: none;\n}\n\ntable {\n  border-collapse: collapse;\n}\n\ntable td,\nth {\n  border: 2px solid black;\n}\n\ntable tr:first-child td,\nth {\n  border-top: 0;\n}\ntable tr td:first-child,\nth:first-child {\n  border-left: 0;\n}\n\ntable tr:last-child td {\n  border-bottom: 0;\n}\n\ntable tr td:last-child,\nth:last-child {\n  border-right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
